import { ref } from '@vue/composition-api'
import { useResource } from '@/services/resources'

// TODO move logic to VxTableBody after refactor buttons (Apply, Discard for draggable action)
export const useDraggable = (
  tableRef,
  resource,
  payloadListName = 'positions',
  positionFieldName = 'position'
) => {
  const isDragged = ref(false)
  const resetSortableCache = ref(false)
  const positions = ref(null)
  const { can, loading, callRequest } = useResource(resource)

  const resetOnEvent = () => {
    isDragged.value = false
    resetSortableCache.value = true
    tableRef.value.refresh()
  }

  const onDragged = (value) => {
    isDragged.value = true
    resetSortableCache.value = false
    positions.value = value
  }

  const cancelPositions = () => {
    resetOnEvent()
  }

  const updatePositions = async () => {
    if (can) {
      loading.value = true

      await callRequest({
        data: {
          [payloadListName]: positions.value.map(({ id, position }) => {
            return {
              id,
              [positionFieldName]: position
            }
          })
        }
      })
      resetOnEvent()

      loading.value = false
    }
  }

  return {
    isDragged,
    resetSortableCache,
    positions,
    loading,

    onDragged,
    cancelPositions,
    updatePositions
  }
}
