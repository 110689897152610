<template>
  <div>
    <vx-page-title-actions>
      <vx-button
        :can="canCreate"
        variant="primary"
        :to="createSection"
      >
        Add
      </vx-button>
      <span v-if="isDragged" class="ml-1">
        <vx-button
          class="mr-1"
          variant="success"
          :loading="loading"
          @click="updatePositions"
        >
          Apply
        </vx-button>
        <vx-button
          variant="danger"
          @click="cancelPositions"
        >
          Discard
        </vx-button>
      </span>
    </vx-page-title-actions>
    <vx-table
      ref="tableRef"
      :resource="resource"
      :columns="columns"
      sortable
      :reset-sortable-cache="resetSortableCache"
      @sortableCallback="onDragged"
    />
  </div>
</template>

<script>
import {
  VxCellBadges,
  VxCellButtons,
  VxCellDate,
  VxCellImageWithTitle,
  VxTable,
  VxCellDraggable
} from '@/components/table'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import { VxButton } from '@/components/buttons'
import { passDataToResource, sections, useResource } from '@/services/resources'
import { permissions, useCan } from '@/plugins/acl'
import { useDraggable } from '@/services/draggable'
import { ref } from '@vue/composition-api/dist/vue-composition-api'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { buildRoute, path } from '@/router'

export default {
  name: 'Sections',
  components: {
    VxTable,
    VxPageTitleActions,
    VxButton,
    VxCellBadges,
    VxCellDraggable,
    VxCellImageWithTitle,
    VxCellDate,
    VxCellButtons
  },
  setup () {
    const tableRef = ref(null)

    const createSection = path.sectionNew

    const resource = useResource(sections.getAll)

    const {
      isDragged,
      resetSortableCache,
      loading,
      onDragged,
      cancelPositions,
      updatePositions
    } = useDraggable(tableRef, sections.updatePositions)

    const canCreate = useCan(permissions.sectionCreate)
    const canUpdate = useCan(permissions.sectionUpdate)

    const actionButtons = ({ id }) => {
      const editButton = {
        ...buttons.edit,
        can: canUpdate,
        to: buildRoute(path.section, { params: { id } })
      }

      const deleteButton = {
        ...buttons.delete,
        resource: passDataToResource(sections.delete, {
          requestParams: { urlParams: { id } }
        })
      }

      return [editButton, deleteButton]
    }

    const columns = [
      {
        key: 'position',
        component: VxCellDraggable
      },
      { key: 'id' },
      { key: 'title' },
      {
        key: 'is_enabled',
        label: 'Status',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            Enabled: 'success',
            Disabled: 'danger'
          }
        },
        formatter: (value) => {
          return value ? 'Enabled' : 'Disabled'
        }
      },
      {
        key: 'is_framed',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            Framed: 'dark',
            'No frame': 'secondary'
          }
        },
        formatter: (value) => {
          return value ? 'Framed' : 'No frame'
        }
      },
      { key: 'location', component: VxCellBadges },
      { key: 'created_at', component: VxCellDate },
      { key: 'updated_at', component: VxCellDate },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: actionButtons(item)
        })
      }
    ]

    return {
      tableRef,
      resource,
      columns,
      canCreate,
      createSection,

      onDragged,
      isDragged,
      loading,
      cancelPositions,
      updatePositions,
      resetSortableCache
    }
  }
}
</script>
